.layout-category-list {
    width: 1190px;
    margin: 0 auto;
    min-height: 600px;
}

.layout-category-list .channel-l {
    width: 210px;
    height: auto;
    float: left;
    clear: both;
}

.layout-category-list .channel-l .shopping-li {
    border-bottom: 1px solid #007857;
    position: relative;
    width: 210px;
}

.layout-category-list .channel-l .channel-li-li {
    border-left: 1px solid #007857;
    border-right: 1px solid #007857;
    cursor: pointer;
    font-family: "Microsoft YaHei", "黑体", sans-serif;
    height: 34px;
    line-height: 1;
    padding-left: 0;
    transition: padding-left 0.3s ease 0s;
}

ul,
ol,
li {
    list-style: none;
}

.layout-category-list .channel-l .channel-li-li-border {
    border-bottom: 1px solid #eee;
    display: block;
    height: 100%;
    margin: 0 10px;
}

.layout-category-list .channel-l .channel-li-li .icon-cog {
    display: inline-block;
    float: left;
    height: 14px;
    margin: 10px 8px 10px 0;
    overflow: hidden;
    width: 14px;
}

.tools-index-icon-0 {
    background-position: -20px 0;
    background: url("./img/index_icon.png") 0 0 no-repeat
}

.layout-category-list .channel-l .shopping-li span.firstLevel {
    color: #333;
    display: inline-block;
    font-size: 14px;
    padding-top: 10px;
}

.channel-li-li .glyphicon-right {
    float: right;
    position: relative;
    top: 13px;
    right: 10px;
    width: 5px;
    height: 10px;
    background: url(./img/icon_arrow.png) no-repeat;
}

.channel-li-li .hover-icon-right {
    display: none;
    float: right;
    position: relative;
    top: 13px;
    right: 10px;
    width: 5px;
    height: 10px;
    background: url(./img/iconArray.png) no-repeat;
}

.layout-category-list .channel-r {
    width: 960px;
    height: auto;
    margin-left: 20px;
    float: left;
}

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}


.slider {
    height: 90px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.sliderbox {
    position: relative;
}

.slidernav {
    border-radius: 8px;
    bottom: 0px;
    font-size: 0;
    left: 42%;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    display: block;
}

.slidernav li {
    background-color: #fff;
    border-radius: 100%;
    display: inline-block;
    height: 8px;
    margin-right: 5px;
    margin-left: 5px;
    width: 8px;
    cursor: pointer;
}

.slidernav .current {
    background-color: #ff7200;
}

.showTable{
    margin-top: 20px;
}

.layout-category-list .channel-r .shielter {
    margin-top: 25px;
    margin-bottom: 15px;
}

.layout-category-list .channel-r .cat-title {
    font-family: "Microsoft YaHei", "黑体", sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    margin: 0 0 0 10px;
    position: relative;
}

.layout-category-list .channel-r .cat-title .cat-title-name {
    background-color: #fff;
    padding-right: 8px;
    position: absolute;
    font-size: 14px;
}

a {
    text-decoration: none;
    color: #333;
}
.layout-category-list .channel-r .cat-title .mid-border {
    border-top: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.layout-category-list .channel-r .catNodes {
    cursor: pointer;
    float: left;
    height: 130px;
    margin: 10px 20px 5px 0;
    width: 100px;
}

.layout-category-list .channel-r .nodeImg {
    border: 1px solid #eee;
    border-radius: 7px;
    height: 82px;
    margin: 0 10px;
    position: relative;
    width: 82px;
}

.layout-category-list .channel-r .nodeImg img {
    border-radius: 7px;
    height: 80px;
    width: 80px;
}
.layout-category-list .channel-r .catNodes .node-title {
    font-family: "Microsoft YaHei", "黑体", sans-serif;
    font-size: 12px;
    line-height: 16px;
    margin: 5px 10px;
    text-align: center;
}

.layout-category-list .channel-l .shopping-li li.on {
    background-color: #048c67;
}

.layout-category-list .channel-l .shopping-li li.on .firstLevel{
    color: white;
}