
.search{
    position: relative;
    width: 100%;height: 120px;
}
.logo{
    position: absolute;
    left:0px;top:20px;
    width: 100px;
    height: 80px;
    background-color: #9c9c9c;
}
.ewm{
    position: absolute;
    right:96px;
    top:20px;
    width: 76px;
    height: 90px;
    text-align: center;
    background-color: #fff;
}
.ewm>a{
    color:#f05633;
}
.ewm>img{
    width: 62px;
    height: 62px;
}
.search-wrap{
    position: relative;
    left: 22%;
    top:20px;
}
.search-bd{
    position: relative;
    width: 630px;height: 42px;
    background: #fff;
    border-radius: 20px;
    margin-top: 12px;
    border: 1px solid #ff5000;
    
}
.search-triggers{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 200;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left: 0;
    height: 40px;
    background: #fff;
    width: 74px;
  
}
.search-triggers span{
    padding:20px;
    line-height: 40px;
    text-align: center;
}
.search-triggers-list{
    display: none;
    position: absolute;
    top:32px;
    
    background-color: #fff;

}
.search-triggers-list li{
    height: 38px;line-height: 38px;
    background-color: #fff;
    padding:0 20px;
}
.search-triggers:hover .search-triggers-list{
    display: block;
}
.search-input-box{
    position: absolute;
    left:75px;top:0px;
}
.search-input-box input{
    width: 200px;height: 40px;
    background: url("./img/sousuo.png")no-repeat;
   background-position: 10px 10px;
    background-size: 20px 20px;
    padding-left:34px;
    border:none;
    outline: none;
    color:#9c9c9c;

}
.search-button{
    position: absolute;
    margin-top: 3px;
    width: 73px;
    height: 34px;
    border-radius: 20px;
    right: 3px;
    /* 渐变背景 */
    background-image: linear-gradient(to right,#ff9000 0,#ff5000 100%);
    background-repeat: repeat-x;

    font-size: 18px;
    font-weight: 700;
    color: #FFF;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
    border: none;

}
.search-list{
    position: absolute;
    top:45px;

}
.search-wrap li{
    float: left;
    padding-left: 10px;
}


/* 搜索部分结束 */