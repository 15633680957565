.layout-ad-slide-industry {
    width: 100%;
    height: 90px;
    margin-bottom: 10px;
    background: rgba(246, 246, 246, 1);
}

ul, ol, li {
    list-style: none;
}


.layout-ad-slide-industry .ad-box li {
    float: left;
    width: 134px;
    height: 90px;
    margin-right: 5px;
    background: rgba(255, 255, 255, 1);
    position: relative;
}
.ad-box li a {
    display: inline-block;
    cursor: pointer;
    margin: 0px 0px 0px 11px;
    padding-top: 16px;
}

a {
    text-decoration: none;
    color: #333;
}

.ad-box li a .icon-industry {
    width: 56px;
    height: 52px;
}