.ehsy-vip-exclusive-rights {
    text-align: center;
}
.features-item {
    float: left;
    width: 32%;
    text-align: center;
    margin-top: 10px;
}
.features-item .features-item-des {
    color: #333333;
    margin-top: 6px;
    margin-bottom: 6px;
}