.demo-logo-vertical {
    height: 32px;
    margin: 16px;
    background: rgba(255,255,255,.2);
    /* background-image:image("Estee/images/0126.png"); */
    border-radius: 6px;
}


.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    color: #9c9c9c;

}

.gouwuche,
.gengduo {
    color: #ff6a05;
}


* {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

li {
    list-style: none;
}