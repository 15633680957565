
.main-top {
    width: 1190px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #2e9b64;
  }
  
  .main-top>ul>li:hover>a {
    color: #ff5a00;
  }
  
  .main-top ul li {
    float: left;
    padding-left: 20px;
  }
  
  #ztsc {
    width: 210px;
    overflow: hidden;
    text-align: left;
    background-color: #2e9b64;
  }
  
  #ztsc a {
    color: white;
    font-weight: 700;
    font-size: 16px;
    padding-left: 30px;
  }
  
  .tm a {
    color: #ff3761;
    font-weight: 700;
    font-size: 16px;
  }
  