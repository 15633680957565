.layout-category-content, .layout-search-content {
    width: 1109px;
    margin: 0 auto;
}

.layout-category-content .crumbs {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;

    padding-bottom: 15px;
    line-height: 24px;
    height: 24px;
    overflow: visible;
}

.filter {
    /* margin: 10px; */
    font-size: 12px;
}

.brand, .category, .series {
   
    border: 1px solid #ddd;
    color: #666;
    height: 42px;
    margin-bottom: -1px;
    overflow: hidden;
    position: relative;
    transition: height .3s ease-out 0s;
}

.showIf {
    background-color: #eeeeee;
    height: 40px;
    margin-top: 20px;
    width: 100%;
}

.brandName, .categoryName, .productName {
    background-color: #eee;
    display: inline-block;
    height: 42px;
    padding-left: 20px;
    padding-right: 10px;
    transition: height .3s ease-out 0s;
    vertical-align: top;
    width: 80px;
}

.category-name-span {
    display: table-cell;
    height: 42px;
    vertical-align: middle;
}

.categoryWrap {
    color: #333;
    display: inline-block;
    line-height: 40px;
    width: 900px;
}

.categoryWrap a {
    color: #06c;
    top: 2px;
    transition: background-color .1s ease-in 0s;
}

.categoryEach {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0 1px;
}

.showIf .sort {
    cursor: pointer;
    display: inline-block;
    height: 100%;
}
ul, ol, li {
    list-style: none;
}

.sort.sort-0 .default {
    background-color: #007857;
    color: #ffffff;
}

.sort li {
    border-right: 1px solid #dddddd;
    float: left;
    font-size: 12px;
    height: 100%;
    line-height: 38px;
    margin-left: -1px;
    position: relative;
}
.sort a {
    color: inherit;
    display: inline-block;
    line-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
}
.sort .price a {
    padding-right: 30px;
    position: relative;
    z-index: 0;
}
.showModal {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    cursor: pointer;
    float: right;
    font-size: 0;
    height: 40px;
    line-height: 40px;
    width: 132px;
}
.showModal .bigImg {
    float: left;
}
.showModal li {
    display: inline-block;
    font-size: 12px;
    width: 64px;
}
.bigImg-icon {
    background: url(./img/icon40.png) no-repeat scroll -90px -10px;
    display: inline-block;
    height: 20px;
    left: 8px;
    position: relative;
    top: 10px;
    vertical-align: top;
    width: 20px;
}
.showModal span {
    left: 4px;
    position: relative;
}
.list.active {
    background-color: #007857;
    color: #ffffff;
}
.showModal .list {
    float: right;
}
.showModal li {
    display: inline-block;
    font-size: 12px;
    width: 64px;
}
.list.active .list-icon {
    background: url(./img/icon40.png) no-repeat scroll -49px -50px;
    color: #ffffff;
}
.list-icon {
    background: url(./img/icon40.png) no-repeat scroll -90px -50px;
    display: inline-block;
    height: 20px;
    left: 8px;
    position: relative;
    top: 10px;
    vertical-align: top;
    width: 20px;
}
.total-count {
    color: #333333;
    float: right;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 130px;
}

.sortWrap.asce {
    background: url(./img/icon40.png) no-repeat scroll -11px -172px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 10px;
    width: 20px;
    z-index: 1;
}
.searchTitle-filter {
    margin-bottom: 10px;
}
.filterThing {
    border: 1px dashed #007857;
    display: inline-block;
    margin: 0 5px 5px 0;
    padding: 3px 1px;
}
.filterThing a.removelink-href {
    margin-left: 5px;
    margin-right: 5px;
}
.filterThing i {
    background: url(./img/icon40.png) no-repeat scroll -12px -13px;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    vertical-align: top;
    width: 16px;
}

.p-image {
    height: 82px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 82px;
}
.image-div {
    min-height: 190px;
} 
.p-image img {
    cursor: pointer;
    height: 100%;
    width: 100%;
}


.p-name {
    color: #007857;
    cursor: pointer;
    font-size: 12px;
    line-height: 22px;
    height: 44px;
    overflow: hidden;
    background-color: transparent;
    display: block;
    height: 46px;
    left: 92px;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 14px;
    width: 158px;
}

.product {
    display: block;
    height: 100px;
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    padding-top: 0;
    position: relative;
    width: 100%;
}

.important-area {
    color: #c01818;
    font-weight: 700
}