
  .small-img{
    position: relative;
  }
  .small-img img{
    width: 500px;
    height: 500px;
  }
  .big-img{
    width: 500px;
    height: 500px;
    position: absolute;
    overflow: hidden;
    top: 128px;
    left: 50%;
    border: 1px solid red
  }
  .big-img img{
    position: absolute;
    width: 1000px;
    height: 1000px;
  }
  .move-bar{
    width: 150px;
    height: 150px;
    position: absolute;
    top:0;
    left: 0;
    background-color: rgba(255,255,255,0.3);
  }