label,
#env,
#brand,
#priority,
#feature {
    display: inline-block;
    margin-right: 10px;
}

label {
    margin-top: 10px;
    width: 100px;
    margin: 10px;
}

.row{
    margin: 20px;
}
.col {
    display: flex;
}