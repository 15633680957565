.layout-home-floor .floor-box .floor-content {
    width: 100%;
    margin-top: 30px;
    background: #fff;
    border-top: 1px solid #eee;
}
.ant-tabs-content{
    border-right: 1px solid #eee;
    margin-left: -10px;
}

.layout-home-floor .floor-content .recommendations {
    margin-top: 10px !important;
    margin: 0 auto;
    overflow: hidden;
    width: 1200px;
    background: #f3f3f3;
}

.layout-home-floor .floor-content .recommend-each .recommend-brief {
    float: left;
    height: 546px;
    padding: 20px 10px 0;
    position: relative;
    width: 210px;
}

.layout-home-floor .floor-content .recommend-each .recommend-brief img.background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.layout-home-floor .floor-content .recommend-each .recommend-brief .recommend-title {
    color: #fff;
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 10px;
    position: absolute;
    z-index: 2;
}

.layout-home-floor .floor-content .recommend-each .recommend-brief ul {
    position: absolute;
    top: 70px;
    z-index: 2;
}

/* ul, ol, li {
    list-style: none;
    float: left
} */

.layout-home-floor .floor-content .recommend-each .recommend-brief .recommend-link {
    display: block;
    position: absolute;
    bottom: 0;
    height: 360px;
    width: 180px;
    z-index: 2;
    background: #fff;
    opacity: 0;
    filter: alpha(opacity=0);
}

.layout-home-floor .floor-content .recommend-each .recommend-brief ul > li > a:hover {
    background-color: white;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #007857;
}

.layout-home-floor .floor-content .recommend-each .recommend-brief ul > li > a {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: white;
    height: 24px;
    line-height: 24px;
    margin: 0 10px 10px 0;
    padding: 1px 5px;
    transition: all 0.25s ease-out 0s;
}


.layout-home-floor .floor-content .recommend-each .products {
    background-color: white;
    float: left;
    height: 546px;
    padding: 0px 0px 0px 20px;
    width: 990px;
    overflow: hidden;
}

.layout-home-floor .floor-content .recommend-each .brands {
    background-color: white;
    float: left;
    height: 566px;
    margin-left: 5px;
    width: 200px;
}

.layout-home-floor .floor-content .recommend-each .brands .title {
    border-bottom: 1px solid #eee;
    font-size: 14px;
    height: 42px;
    line-height: 42px;
    margin: 0 10px;
}

.layout-home-floor .floor-content .recommend-each .ranking {
    background-color: white;
    float: left;
    height: 566px;
    margin-left: 5px;
    width: 250px;
    overflow: hidden;
}

.layout-home-floor .floor-content .recommend-each .ranking .title {
    border-bottom: 1px solid #eee;
    font-size: 14px;
    height: 42px;
    line-height: 42px;
    margin: 0 10px;
}