/* Main product container */
.product-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping on small screens */
  justify-content: space-between;
  max-width: 1200px;
  margin: 20px auto;
}

/* Product Image Area */
.product-image-area {
  position: relative;
  width: 45%;  /* Keep the width of the image area */
  padding: 20px 10px 20px 20px;  /* Reduced right padding to decrease space on the right */
}

/* Thumbnail container */
.thumbnail-container {
  margin-top: 15px;
}

.thumbnail {
  width: 50px;  /* Thumbnails remain at 50px width */
  height: 50px; /* Thumbnails remain at 50px height */
  object-fit: cover;
  margin: 10px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.thumbnail:hover {
  opacity: 0.7;
}

/* Main product image */
.image-container img {
  width: 100%;  /* Reduced the size of the main image */
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

/* Magnifier */
.magnifier {
  position: absolute;
  top: 0;
  left: 100%;
  width: 200px;
  height: 200px;
  margin-left: 20px;
  background-repeat: no-repeat;
  background-size: 200% 200%;
  visibility: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Show magnifier on hover */
.product-image-area:hover .magnifier {
  visibility: visible;
}

/* Product Details */
.product-details {
  width: 100%;  /* Full width on smaller screens */
  padding: 0px;
  box-sizing: border-box;
  flex-basis: 45%;  /* Takes up 45% of the row on larger screens */
  max-width: 600px;
}

/* Product Title */
.product-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Price Area */
.product-price{
  margin-top: 5px;
}
.product-price p {
  font-size: 18px;
}

.price-excluding-tax {
  text-decoration: line-through;
  margin-right: 15px;
}

.price-including-tax {
  color: red;
  font-size: 20px;
}

/* Category Selector */
.category-selector {
  margin-top: 8px;
}

/* Quantity Selector */
.quantity-selector {
  margin-top: 8px;
}

/* Action Buttons */
.action-buttons {
  margin-top: 20px;
}

.add-to-cart-btn, .buy-now-btn {
  background-color: #2e9b64;
  color: white;
  border: 1px solid #2e9b64;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s;
}

.add-to-cart-btn:hover, .buy-now-btn:hover {
  background-color: #248d52;
}

.add-to-cart-btn i, .buy-now-btn i {
  margin-right: 8px;
  font-size: 18px;
}

/* Payment Methods */
.payment-methods {
  margin-top: 30px;
}

.payment-methods h3 {
  margin-bottom: 10px;
}

.payment-icons {
  display: flex;
}

.payment-icon {
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  padding: 10px 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.payment-icon i {
  margin-right: 8px;
}

/* Hover Effect on Payment Icons */
.payment-icon:hover {
  background-color: #e0e0e0;
}

/* Thumbnail Image Hover */
.thumbnail-container img:hover {
  opacity: 0.7;
}


/* Tab navigation */
/* Tabs container */
.product-tabs-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  flex-basis: 50%;  /* Adjusts the width of the tab section */
  max-width: 450px; /* Max width to match product details */
}


.tabs {
  margin-top: 30px;
}

.tab-list {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
}

.tab {
  padding: 12px 20px;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #ddd;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: #e0e0e0;
}

.tab.active {
  background-color: #2e9b64;
  color: white;
}

/* Tab content */
.tab-content {
  margin-top: 20px;
}

.tab-panel {
  display: none;
}

.tab-panel img {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

/* Show active tab */
.tab-panel.active {
  display: block;
}

