.ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

#root {
  font-size: 14px;
  width: 100%;
  background: url(./0126.png) no-repeat center top;
  /* background-size: 100% 100%; */
  /* background-position: 0 0; */
}

.ant-layout {
  background: none;
}

.main{
  width: 1190px;
  margin-left: auto;
  margin-right: auto;

}


.main-content {
  width: 1190px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-color: #fff;
  border-radius: 15px 15px 15px 15px;
}

.main-content .main-top .jhs a {
  color: #ff0036;
  font-weight: 700;
  font-size: 16px;
}

.main-content .main-top .tmcs a {
  color: #33c900;
  font-size: 16px;
  font-weight: 700;
}

.main-content .main-top .main-content {
  width: 100%;
}


.layout-home-floor .floor-box {
  position: relative;
}

.layout-home-floor .floor-box {
  width: 1200px;
  margin: 0 auto;

  padding-bottom: 20px;
}