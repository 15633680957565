.main-content1 {
    width: 1190px;
    height: 528px;
    /* background-color: rgb(46, 85, 46); */
}

.service-list {
    position: relative;
    clear: both;
    width: 210px;
    height: 525px;
    float: left;
    background-color: #3fac75;
}
.tree-sub-li{
    padding-left: 0px !important;
}

.service-list li {
    line-height: 32px;
    height: 32px;
    padding-left: 25px;
    padding-right: 19px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    width: 100%;
    overflow: hidden;
}

.service-list li a {
    float: left;
    color: white;
}

.service-list li:hover {
    background-color: white;
    color: #3fac75;
}



.service-list>ul>li:hover .service-list-t {
    color: #3fac75;
}

.xiangyou {
    float: right;
    margin-top: 8px;
    width: 18px;
    height: 18px;
    margin-right: 2px;
}

.service-detail {

    display: none;
    position: absolute;
    background-color: #fff;
    left: 210px;
    top: 0;
    /* width: 700px; */
    /* height: 518px; */
    border-radius: 10px;
    z-index: 100;
    /* border: 1px solid #ff5a00; */

}

.service-list li:hover .service-detail {
    display: block;
}



.core {
    width: 690px;
    height: 415px;
    float: left;
    /* background-color: purple; */
    overflow: hidden;
    margin-left: 10px;
    margin-top: 10px;
}

/* .box {
    width: 690px;
    margin: 0px auto;
    overflow: hidden;
    position: relative;
} */

.content-img{
    width: 690px;
    height: 415px;
}


/* 轮播图结束 */
.Tmall {
    width: 100%;

    height: 230px;
    /* background-color: green; */
}

.Tmall-title {
    width: 100%;
    height: 40px;
    text-align: center;
}

.Tmall-title>img {
    float: left;
    width: 82px;
    height: auto;
    margin-top: 10px;
}

.Tmall-title>span {
    float: left;
    font-size: 12px;
    color: #999;
    padding-top: 12px;
    padding-left: 6px;
}

.Tmall-detail {
    width: 100%;
    height: 190px;
    /* background-color: pink; */
    overflow: hidden;
}

.Tmall-detail img {
    float: left;
    width: 240px;
    height: auto;
    margin-left: 15px;
    border-radius: 10px;
}

.sub-column {
    width: 160px;
    height: 512px;
    /* background-color: yellow; */
    float: left;
    margin-left: 10px;
    margin-top: 10px;
}

.tbh-focus {
    overflow: hidden;
    height: 280px;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
}

.focus-img {
    border-radius: 12px;
    width: 100%;
    height: auto;
}

.hb-tanx-down {
    margin-top: 1px;
    width: 100%;
}

.hb-tanx-down h5 {
    font-size: 12px;
    font-weight: 400;
    color: #a1a1a1;
    line-height: 24px;

}

.hb-tanx-down img {
    width: 100%;
    height: auto;
    border-radius: 12px;
}

.col-right {
    float: right;
    width: 270px;
    height: 512px;
    margin-top: 10px;
    /* background-color: red; */
    border-right: 1px solid #eee;
}

.member {
    width: 100%;
    height: 200px;
    /* padding-right: inherit; */

    text-align: center;
    background-repeat: no-repeat;
}

.tx>img {
    border-radius: 50%;
}

.member-bd-jb {
    width: 226px;
    height: 26px;
    margin: 0 auto;
}

.member-bd>ul>li {
    float: left;
    background-color: #ffe4db;
    margin-left: 5px;
    border-radius: 9px;
}

.member-bd-jb .jb {
    float: left;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    background: url("./img/mb.png")no-repeat;
    background-position: 0px -573px;
}

.member-bd-jb .jb2 {
    float: left;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    background: url("./img/mb.png")no-repeat;
    background-position: 0px -529px;
}

.member-bd>ul>li a {
    float: left;
    padding-left: 3px;
    padding-right: 5px;
    color: #ff6904;

}

.member-bd-btnbox {
    width: 256px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.member-bd-btn {
    float: left;
    width: 120px;
    height: 26px;
    color: #fff;
    background-color: #3fac75;
    line-height: 26px;
    margin-left: 6px;
    border-radius: 4px;
    text-align: center;

}

.tbn-tipoff {
    margin-top: 50px;
}

.min {
    width: 290px;
    height: 70px;
    /* background-color: yellow; */

}

.min>span {
    color: #ff6b05;
}

.min>ul {
    position: relative;
}

.min>ul>li {
    float: left;
    width: 58px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    /* margin-left:10px; */
}

.min>ul>li:hover {
    border-bottom: 2px solid #ff6b05;

}

.min>ul>li a {
    font-weight: 700;
}

.min>ul>li a:hover {
    color: #ff6b05;
}

.min-detail {
    display: none;
    position: absolute;
    top: 28px;
    left: 0;
    background-color: #fff;
    width: 290px;
    height: 40px;
    color: #ff6b05;
}

.min>ul>li:hover .min-detail {
    display: block;
}

.conve {
    width: 290px;
    height: 72px;
    /* overflow: hidden; */
    z-index: -2;
    /* background-color: pink; */

}

.conve ul {
    position: relative;
}

.conve li {

    float: left;
    width: 70px;
    height: 70px;
    border: 1px solid #f4f4f4;
    background-color: #fff;
}


.conve img {
    margin: 10px auto 0 0;
}

.conve p {

    position: relative;
    line-height: 20px;
    padding-left: 12px;
    ;
    text-align: center;
}

.conve-detail {
    z-index: 500;
    display: none;
    position: absolute;
    top: 68px;
    left: 0px;
    ;
    background-color: #fff;
    border: 1px solid #ff4400;
    width: 286px;
    height: 160px;
}

.conve>ul>li:hover {
    border: 1px solid #ff4400;
}

.conve>ul>li:hover .conve-detail {
    display: block;
}

.App {
    margin-top: 10px;
    width: 290px;
    height: 77px;
    /* background-color: green; */
    overflow: hidden;
}

.A-t {
    color: #3C3C3C;
    width: 100%;
    height: 27px;
    /* background-color: pink; */
    line-height: 27px;
    text-align: center;
}

.A-t1 {
    float: left;
    font-weight: 700;
    /* font-size: 16px; */
    padding-left: 10px;
}

.A-t2 {
    float: right;
    font-size: 12px;
    padding-right: 10px;
}

.App>ul>li {
    float: left;
    width: 58px;
    height: 50px;
    text-align: center;
}

.App>ul>li>img {
    width: 40px;
    height: 40px;
    border-radius: 12px;
}


.video-container {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 112px;
}

video {
    object-fit: fill;
    /*ie浏览器不生效*/
    width: 100%;
    height: 100%;
}

/*不显示时间条*/
/*video::-webkit-media-controls { display:none !important;}*/

/* 第一部分结束 */



.category-tree-icon {
    color: white;
    line-height: 28px !important;
    margin-right: 10px;
    height: 16px;
    width: 16px;
    display: inline-block;
    float: left;
}
