/* 头部开始 */



.site-nav {

    width: 100%;
    height: 36px;
    background-color: #f5f5f5;
    line-height: 36px;
    text-align: center;
}

.site-nav li a {
    color: #222;
}

.site-nav-center>ul>li:hover>a {
    color: #ff5a00;
}

.site-nav-center {
    position: relative;
    width: 1190px;
    margin-left: auto;
    margin-right: auto;
    /* background-color: yellow; */
}

.site-nav-center-l {
    position: absolute;
    left: 0;
    top: 0;

}

.site-nav-center li {
    position: relative;
}

.site-nav-center li:hover .site-nav-menu {
    display: block;
    z-index: 200;
}

.site-nav-menu {
    display: none;
    position: absolute;
    top: 36px;
    left: 0;
    height: 100px;

    background-color: #ffffff;
    /* overflow: scroll; */
}

#wangzhan {
    display: none;
    z-index: 300;
    overflow: hidden;
    position: absolute;
    right: 0;
    left: auto;
    width: 988px;
    min-height: 258px;
    line-height: normal;
    border: 1px solid #eee;
    background: #fff;
}

#wangzhan>li {
    float: left;
    font-size: 14px;
    font-weight: 700;
    padding-top: 5px;
    width: 20%;
    height: 25px;
    overflow: hidden;
}

.site-nav-center li:hover #wangzhan {
    display: block;
}

.site-nav-menu li {
    width: 100px;
    height: 30px;
    padding-left: 20px;
    line-height: 30px;
    background-color: #fff;
    text-align: left;
}

.site-nav-menu li:hover {
    background-color: #f4f4f4;
}

.site-nav-center-l li {
    padding-left: 20px;
    color: #6c6c6c;
    float: left;
}

.site-nav-center-r {
    position: absolute;
    top: 0;
    right: 0;
    width: 754px;
    height: 30px;

}

.site-nav-center-r li {
    padding-right: 20px;
    color: #6c6c6c;
    float: left;
}

/* 头部结束 */