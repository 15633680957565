ul, ol, li {
    list-style: none;
}

.product-list {
    background: #fff none repeat scroll 0 0;
    margin: 20px 0;
}

.product-list > li {
    height: 243px;
    margin: 0 0 20px;
    padding-left: 7px;
    padding-right: 6px;
    transition: all 0.25s ease-out 0s;
    width: 170px;
    float: left;
}

.product-list .product-container-web {
    padding: 0;
    width: 170px;
}

.product-list .product-container-web .img-container {
    display: block;
    height: 170px;
    width: 170px;
}

 .floor-content img {
    border: 0px;
    vertical-align: middle;
    width: 100%;
    height: 170px;
    width: 170px;
}

.product-list .product-container-web .product-name-container {
    height: 20px;
    margin: 7px 0 0;
}

.product-name-container {
    color: #333;
    display: -webkit-box;
    font-size: 12px;
    height: 40px;
    line-height: 20px;
    margin: 10px 0 0;
    overflow: hidden;
    padding: 0;
    text-align: left;
    width: 100%;
}
a {
    text-decoration: none;
    color: #333;
}
.product-name-container .product-name {
    display: block;
    font-size: 12px;
    height: 20px;
    line-height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 170px;
    overflow: hidden;
}
.product-container-web .product-price-container {
    margin: 5px 0 0;
}
.product-price-container .price-sale {
    color: #e60012;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

.ant-tabs-tab > div{
    color: #333 p !important;
}